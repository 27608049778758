<template>
  <v-container>
    <v-row class="ma-2">
      <v-col>
        <Novo />
      </v-col>
      <v-col>
        <v-btn @click="acao('enviadas')">Enviadas</v-btn>
      </v-col>
      <v-col>
        <v-btn @click="acao('recebidas')">Enviadas</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table 
          :items="lista_sugestoes"
          :headers="headers"
          :search="search"
        >
          <template v-slot:top>
            <v-row dense>
              <v-col cols="10">
                <v-text-field
                  v-model="search"
                  label="Pesquisar"
                  class="mx-4"
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="text-right">
                <Add />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.destinatarios`]="{ item }">
            <p 
              v-for="(v,i) in item.destinatarios"
              :key="`d${i}`"
            >
              {{v.text}}
            </p>
          </template>
          <template v-slot:[`item.ver`]="{ item }">
            
              {{item.id}}
            
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name:"Mensagens",
  components:{
    Novo:()=>import('./Novo')
  },
  data:()=>({
    search:'',
    headers:[
        { text: 'Id',align: 'start',sortable: true, value: 'id',},
        { text: 'Remetente',align: 'start',sortable: true, value: 'remetente.text',},
        { text: 'Destinatario',align: 'start',sortable: true, value: 'destinatario.text',},
        { text: 'Destinatarios',align: 'start',sortable: true, value: 'destinatarios',},
        { text: 'Assunto',align: 'start',sortable: true, value: 'assunto',},
        { text: 'Mensagem',align: 'start',sortable: true, value: 'mensagem',},
        { text: 'Ver',align: 'center',sortable: false, value: 'ver',},
    ]
  }),
  computed:{
    ...mapGetters(['loading','lista_sugestoes'])
  },
  methods:{
    acao(val){
      this.$store.dispatch('sugestoes',{
        url:`mensagens/mensagens/?pasta=${val}`,
        method:'get',
        instancia:'sugestoes',
      })
    }
  },
  mounted(){
    this.acao('recebidas')
  }

}
</script>